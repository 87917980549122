<template>
  <div class="top-bar">
    <div class="top-bar__title">
      Especialidade:
      <strong>
        {{ appointment.specialty.name | toSpecialtyName }}
      </strong>

      <span class="appointment-date__title-change">
        (
        <router-link
          class="appointment-date__title-change--link"
          :to="{ name: specialtyRoute }"
        >
          alterar
        </router-link>
        )
      </span>
    </div>

    <div
      v-show="isPaymentMethod"
      class="top-bar__title top-bar__title--right"
      @click="scrollToSection('footer')"
    >
      Pagamento via

      <strong>
        <span>
          {{ payment.method.label }}
        </span>
      </strong>

      <span class="appointment-date__title-change">
        (
        <router-link
          class="appointment-date__title-change--link"
          :to="{ name: paymentMethodRoute }"
        >
          alterar
        </router-link>
        )
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import scrollToSection from '@/mixins/scrollToSection';
import toSpecialtyName from '@/filters/specialtyName';

export default {
  name: 'TopBar',

  mixins: [scrollToSection],

  filters: {
    toSpecialtyName,
  },

  computed: {
    ...mapGetters('ui', ['progressTracker']),
    ...mapGetters('scheduling', ['appointment', 'payment']),
    ...mapGetters('user', ['logged']),

    isPaymentMethod() {
      return this.progressTracker
        .find((step) => step.step > 2)
        .isCurrent;
    },

    specialtyRoute() {
      if (this.logged) {
        return 'UserSchedulingSpecialty';
      }

      return 'AppointmentSchedulingSpecialty';
    },

    paymentMethodRoute() {
      if (this.logged) {
        return 'UserSchedulingPaymentMethod';
      }

      return 'AppointmentSchedulingPaymentMethod';
    },
  },
};
</script>
