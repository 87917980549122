<template>
  <section
    v-if="reservationStartTime"
    :class="{ 'timer--blink': ui.blinkCard }"
    class="timer"
  >
    <icon
      name="time"
      color="neutral"
      class="timer__icon"
    />

    <div class="timer__col">
      <p class="timer__text">
        Tempo restante
      </p>

      <p
        :class="{ 'timer__text--time-out': ui.redText }"
        class="timer__text timer__text--time"
      >
        {{ time }}
      </p>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  format,
  startOfDay,
  addSeconds,
  subSeconds,
  differenceInSeconds,
} from 'date-fns';
import { zonedTimeToUtc, toDate } from 'date-fns-tz';
import server from '@/api/general/server';
import Icon from '@/components/ui/Icon.vue';

const MAX_RESERVATION_MIN = 5;
const TIMEZONE = 'America/Sao_Paulo';

export default {
  name: 'Timer',

  components: {
    Icon,
  },

  data() {
    return {
      secondsCounter: null,
      initialTime: null,
      timer: null,
      ui: {
        blinkCard: false,
        redText: false,
      },
    };
  },

  computed: {
    ...mapGetters('scheduling', ['reservationStartTime']),
    ...mapGetters('partner', ['partner']),
    ...mapGetters('user', ['logged']),

    time() {
      if (parseInt(this.secondsCounter, 10) <= 0) {
        return '00:00:00';
      }

      try {
        return format(this.initialTime, 'HH:mm:ss');
      } catch {
        return '00:05:00';
      }
    },
  },

  watch: {
    reservationStartTime: {
      deep: false,
      immediate: true,
      handler(time) {
        if (time) {
          this.init();
        }
      },
    },

    secondsCounter: {
      deep: false,
      immediate: false,
      handler(seconds) {
        if (parseInt(seconds, 10) <= 0) {
          clearTimeout(this.timer);
          this.notifyUser();
          return;
        }

        if (parseInt(seconds, 10) === 60) {
          this.ui.blinkCard = true;
          return;
        }

        if (parseInt(seconds === 10, 10)) {
          this.ui.redText = true;
        }
      },
    },
  },

  created() {
    document.addEventListener(
      'visibilitychange',
      () => {
        if (!document.hidden) {
          this.init();
        }
      },
      false,
    );
  },

  destroyed() {
    this.destroy();
  },

  methods: {
    ...mapActions('ui', [
      'openModalDialog',
      'closeModalDialog',
      'completeProgressTrackerStep',
      'resetProgressTrackerStep',
    ]),

    ...mapActions('scheduling', ['resetScheduling']),

    init() {
      this.destroy();

      let initialTime = null;

      server()
        .getTime()
        .then((time) => {
          initialTime = toDate(time, { timeZone: TIMEZONE });
        })
        .catch(() => {
          initialTime = zonedTimeToUtc(new Date(), TIMEZONE);
        })
        .finally(() => {
          const reservationStartTime = toDate(this.reservationStartTime, { timeZone: TIMEZONE });
          const diffSeconds = differenceInSeconds(initialTime, reservationStartTime);
          this.secondsCounter = (MAX_RESERVATION_MIN * 60) - diffSeconds;

          this.initialTime = addSeconds(startOfDay(initialTime), this.secondsCounter);

          this.timer = setInterval(() => {
            this.secondsCounter -= 1;
            this.initialTime = subSeconds(this.initialTime, 1);
          }, 1000);
        });
    },

    destroy() {
      clearTimeout(this.timer);
      this.timer = null;
      this.initialTime = null;
      this.secondsCounter = null;
    },

    notifyUser() {
      let initialRoute = '';

      switch (true) {
        case this.logged !== null && this.logged !== '':
          initialRoute = 'UserAccount';
          break;

        case this.partner.hasIframe:
          initialRoute = 'AppointmentScheduling';
          break;

        default:
          initialRoute = 'LandingPage';
      }

      const msg = {
        type: 'alert',
        title: 'Atenção',
        text: `O tempo máximo para o pagamento de sua reserva
          expirou e o horário foi liberado para outros pacientes.
          Por favor, inicie o processo de agendamento novamente.`,
        confirmText: 'Voltar',
        size: 'sm',
        fn: () => {
          this.resetScheduling();
          this.resetProgressTrackerStep();
          this.completeProgressTrackerStep({ step: 1, status: false });
          this.completeProgressTrackerStep({ step: 2, status: false });
          this.completeProgressTrackerStep({ step: 3, status: false });
          this.completeProgressTrackerStep({ step: 4, status: false });
          this.$router.push({ name: initialRoute });
          this.closeModalDialog();
        },
      };

      this.openModalDialog(msg);
    },
  },
};
</script>
